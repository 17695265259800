import { Component, OnInit } from '@angular/core';
import { appConfig } from 'src/app/shared/config/app-config';

@Component({
  selector: 'hg-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  config = appConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
