import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SplashScreenService } from './shared/ui/splash-screen.service';

@Component({
  selector: 'hg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta, private splashScreenService: SplashScreenService) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      const seoData = data['seo'];
      if (seoData) {
        this.title.setTitle(seoData['title']);
        const metaTags: any[] = seoData['metaTags'];
        metaTags.forEach(m => this.meta.updateTag(m));
      }
    });
  }
}
