export const appConfig = {
  logo: 'assets/images/HGofficial 4.png',
  logoSm: 'assets/images/HGofficial-sm.png',
  blackAppstore: 'assets/images/playStoreMedia/appstore.png',
  blackGoogle: 'assets/images/playStoreMedia/google.png',
  appstore: 'assets/images/playStoreMedia/app-store.jpg',
  google: 'assets/images/playStoreMedia/google-play.jpg',
  links: [
    {
      title: 'Home',
      href: '/'
    },
    {
      title: 'About',
      href: '/about'
    },
    {
      title: 'Shop Now',
      href: '/products'
    },
    {
      title: 'Our Sources',
      href: 'sources'
    },
    {
      title: 'Education',
      href: 'education'
    }
  ],
  headerLinks: [

    {
      title: 'Subscriptions',
      routerLink: '/subscription'
    },
    {
      title: 'Grocery Truck',
      routerLink: '/popup-markets'
    },
    {
      title: 'Events',
      routerLink: '/events'
    },
    {
      title: 'Blog',
      routerLink: '/blog'
    },
    {
      title: 'Sustainability',
      routerLink: '/sustainability'
    },
  ],
  viewMoreLinks: [
    {
      title: "About us",
      routerLink: "/about-us"
    },
    {
      title: "Our Sources",
      routerLink: "/our-sources"
    },
    {
      title: "Education",
      routerLink: "/education"
    },
    {
      title: "Careers",
      routerLink: "/careers"
    },
    {
      title: "FAQ",
      routerLink: "/faqs"
    },
    {
      title: "Contact us",
      routerLink: "/contact-us"
    }
  ],
  footerLinks: {
    about: [
      {
        title: 'Who we are',
        routerLink: '/about-us'
      },
      {
        title: 'Blog',
        routerLink: '/blog'
      },
      {
        title: 'Our Sources',
        routerLink: '/our-sources'
      },
      {
        title: 'Education',
        routerLink: '/education'
      },
      {
        title: 'Contact Us',
        routerLink: '/contact-us'
      },
      {
        title: 'Careers',
        routerLink: '/careers'
      }
    ],
    corporate: [
      {
        title: 'Become our Suppliers',
        routerLink: '/our-sources'
      },
      {
        title: 'Sustainability',
        routerLink: '/sustainability'
      },
      // {
      //   title: 'Promotions',
      //   routerLink: '#'
      // }
    ],
    schedule: [
      {
        title: 'Our Grocery Truck',
        routerLink: '/popup-markets'
      }
    ],
    events: [
      {
        title: 'Upcoming Events',
        routerLink: '/events'
      },
      {
        title: 'Our Events',
        routerLink: '/events'
      }
    ],
    learnMore: [
      {
        title: 'Privacy Policies',
        routerLink: '/privacypolicy'
      },
      {
        title: 'Terms & Conditions',
        routerLink: '/terms-and-conditions'
      },
      {
        title: 'FAQ',
        routerLink: '/faqs'
      },
      {
        title: 'Return & Refund',
        routerLink: '/pages/return-and-refund'
      },
      // {
      //   title: 'Delivery Information',
      //   routerLink: '/account'
      // }
      {
        title: 'Request to Delete',
        routerLink: '/pages/request-to-delete'
      },
      {
        title: 'Data Collection Info',
        routerLink: '/pages/data-collection-info'
      }
    ],
    account: [
      // {
      //   title: 'Request to Delete',
      //   routerLink: '/pages/request-to-delete'
      // },
      // {
      //   title: 'Data Collection Info',
      //   routerLink: '/pages/data-collection-info'
      // },
      {
        title: 'My Wishlist',
        routerLink: '/products/my-favorite'
      },
      {
        title: 'My Cart',
        routerLink: '/cart'
      }
    ]
  },
  contact: {
    address: '360 Sukhumvit 65, Bangkok 10110',
    email: 'grocershappy@gmail.com',
    phone: '091-727-8904'
  },
  socialMedia: [
    {
      title: 'facebook',
      icon: '../../../assets/images/media/icon/Asset 1300x.svg',
      link: 'https://www.facebook.com/happygrocers/'
    },
    {
      title: 'twitter',
      icon: '../../../assets/images/media/icon/twitter-svgrepo-com.svg',
      link: 'https://www.twitter.com/happygrocers/'
    },
    {
      title: 'youtube',
      icon: '../../../assets/images/media/icon/Group 796.svg',
      link: 'https://www.youtube.com/@happygrocers244'
    },
    {
      title: 'instagram',
      icon: '../../../assets/images/media/icon/Group 797.svg',
      link: 'https://www.instagram.com/happygrocers/'
    },
    // {
    //   title: 'amazon',
    //   icon: 'M1.04 17.52q.1-.16.32-.02a21.308 21.308 0 0 0    10.88 2.9a21.524 21.524 0 0 0    7.74-1.46q.1-.04.29-.12t.27-.12a.356.356 0 0 1    .47.12q.17.24-.11.44q-.36.26-.92.6a14.99 14.99 0 0    1-3.84 1.58A16.175 16.175 0 0 1 12 22a16.017    16.017 0 0 1-5.9-1.09a16.246 16.246 0 0    1-4.98-3.07a.273.273 0 0 1-.12-.2a.215.215 0 0 1    .04-.12Zm6.02-5.7a4.036 4.036 0 0 1 .68-2.36A4.197    4.197 0 0 1 9.6 7.98a10.063 10.063 0 0 1    2.66-.66q.54-.06 1.76-.16v-.34a3.562 3.562 0 0    0-.28-1.72a1.5 1.5 0 0 0-1.32-.6h-.16a2.189 2.189    0 0 0-1.14.42a1.64 1.64 0 0 0-.62 1a.508.508 0 0    1-.4.46L7.8 6.1q-.34-.08-.34-.36a.587.587 0 0 1    .02-.14a3.834 3.834 0 0 1 1.67-2.64A6.268 6.268 0    0 1 12.26 2h.5a5.054 5.054 0 0 1 3.56 1.18a3.81    3.81 0 0 1 .37.43a3.875 3.875 0 0 1 .27.41a2.098    2.098 0 0 1 .18.52q.08.34.12.47a2.856 2.856 0 0 1    .06.56q.02.43.02.51v4.84a2.868 2.868 0 0 0    .15.95a2.475 2.475 0 0 0    .29.62q.14.19.46.61a.599.599 0 0 1 .12.32a.346.346    0 0 1-.16.28q-1.66 1.44-1.8 1.56a.557.557 0 0    1-.58.04q-.28-.24-.49-.46t-.3-.32a4.466 4.466 0 0    1-.29-.39q-.2-.29-.28-.39a4.91 4.91 0 0 1-2.2    1.52a6.038 6.038 0 0 1-1.68.2a3.505 3.505 0 0    1-2.53-.95a3.553 3.553 0 0    1-.99-2.69Zm3.44-.4a1.895 1.895 0 0 0 .39    1.25a1.294 1.294 0 0 0 1.05.47a1.022 1.022 0 0 0    .17-.02a1.022 1.022 0 0 1 .15-.02a2.033 2.033 0 0    0 1.3-1.08a3.13 3.13 0 0 0 .33-.83a3.8 3.8 0 0 0    .12-.73q.01-.28.01-.92v-.5a7.287 7.287 0 0    0-1.76.16a2.144 2.144 0 0 0-1.76 2.22Zm8.4    6.44a.626.626 0 0 1 .12-.16a3.14 3.14 0 0 1    .96-.46a6.52 6.52 0 0 1 1.48-.22a1.195 1.195 0 0 1    .38.02q.9.08 1.08.3a.655.655 0 0 1 .08.36v.14a4.56    4.56 0 0 1-.38 1.65a3.84 3.84 0 0 1-1.06    1.53a.302.302 0 0 1-.18.08a.177.177 0 0    1-.08-.02q-.12-.06-.06-.22a7.632 7.632 0 0 0    .74-2.42a.513.513 0 0    0-.08-.32q-.2-.24-1.12-.24q-.34    0-.8.04q-.5.06-.92.12a.232.232 0 0    1-.16-.04a.065.065 0 0 1-.02-.08a.153.153 0 0 1    .02-.06Z',
    //   link: '#'
    // }
  ]
};
